import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { darken } from 'polished'
import { ComponentPropsWithoutRef, Fragment } from 'react'

import { bodyGrid, bodyHeading } from '@/features/page-sections'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { ContactBlock } from './ContactBlock'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: Queries.ContactSectionFragment | null
  layout?: 'DEFAULT' | 'CONTACT_PAGE'
}

export const ContactSection = ({
  data,
  layout = 'DEFAULT',
  ...props
}: Props): JSX.Element => {
  const { heading, contactBlocks } = data || {}
  const styles = {
    section: css`
      background: ${colors.red};
      border-top: 2rem solid ${darken(0.05, colors.red)};
      padding: var(--row-54) var(--margin) calc(var(--row-72) - 1.5em);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: var(--margin);
      color: #fff;
      ${mq().m} {
        grid-template-columns: 1fr 1fr;
      }
      ${mq().s} {
        grid-template-columns: 1fr;
      }
      ${layout === 'CONTACT_PAGE' &&
      css`
        ${bodyGrid}
        background: #fff;
        color: ${colors.gray20};
        border: none;
        ${mq().m} {
          grid-template-columns: inherit;
        }
        ${mq().s} {
          grid-template-columns: 1fr;
        }
      `}
    `,
    heading: css`
      font-size: var(--fs-60);
      max-width: 13ch;
      margin: 0 0 0.5em;
      grid-row: 1 / span ${Math.ceil((contactBlocks?.length || 0) / 2)};
      ${mq().m} {
        max-width: none;
        grid-column: 1 / -1;
        grid-row: auto;
      }
      ${layout === 'CONTACT_PAGE' &&
      css`
        ${bodyHeading}
        max-width: none;
      `}
    `,
    contactBlocks: css`
      display: contents;
      ${layout === 'CONTACT_PAGE' &&
      css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: var(--margin);
        grid-row-gap: 1.5em;
        ${mq().m} {
          grid-row-gap: 1em;
        }
        ${mq().s} {
          grid-row-gap: 0em;
          grid-template-columns: 1fr;
        }
      `}
    `,
  }
  if (data) {
    return (
      <section
        css={styles.section}
        {...props}
      >
        <h2 css={styles.heading}>{heading}</h2>
        <div css={styles.contactBlocks}>
          {contactBlocks?.map((block, i) => (
            <ContactBlock
              data={block}
              key={i}
              layout={layout}
            />
          ))}
        </div>
      </section>
    )
  } else return <Fragment />
}

export const ContactSectionFragment = graphql`
  fragment ContactSection on DatoCmsContactSection {
    id: originalId
    __typename
    heading
    contactBlocks {
      ...ContactBlock
    }
  }
`
